import React from 'react';

type Props = { color?: string };

const UserSvg = ({ color = 'white' }: Props) => {
	return (
		<svg
			version='1.1'
			id='Capa_1'
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			x='0px'
			y='0px'
			viewBox='0 0 20 20'
			xmlSpace='preserve'
		>
			<path
				fill={color}
				d='M9,10.2c3.5,0,7,2.3,7.3,7.1c0,0.2-0.1,0.4-0.2,0.5C16,17.9,15.8,18,15.6,18c-1.8,0.1-10.3,0.2-13.3,0
	   c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5C2,12.5,5.5,10.2,9,10.2z M9,1.9c-0.9,0-1.7,0.4-2.3,1c-0.6,0.6-1,1.5-1,2.4
	   s0.3,1.8,1,2.4c0.6,0.6,1.5,1,2.3,1c0.9,0,1.7-0.4,2.3-1c0.6-0.6,1-1.5,1-2.4s-0.3-1.8-1-2.4C10.7,2.3,9.9,1.9,9,1.9z'
			/>
		</svg>
	);
};

export default UserSvg;
