import React from 'react';

type Props = { color?: string };

const EyeClose = ({ color = 'white' }: Props) => {
	return (
		<svg
			width='20'
			height='12'
			viewBox='0 0 20 12'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M17.5 9L15.025 5.604M10 10.5V7M2.5 9L4.969 5.612M1 1C4.6 9 15.4 9 19 1'
				stroke={color}
				strokeWidth='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	);
};

export default EyeClose;
