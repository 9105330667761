'use client';
import React from 'react';

import { Box, CircularProgress, keyframes } from '@mui/material';

const scaleAnimation = keyframes`
  0% {
    transform: scale(0.9);
  }
    50% {
    transform: scale(1);
  }
   
  100% {
    transform: scale(0.9);
  }
`;

export default function LoaderPage() {
	return (
		<Box
			sx={{
				width: '100%',
				height: '90vh',
				position: 'relative',
				backdropFilter: 'blur(10px)',
				zIndex: '9998',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				background: (theme) => theme.palette.primary.light,
				img: {
					animation: `${scaleAnimation} 2s infinite `,
				},
			}}
		>
			<CircularProgress color='primary' />
		</Box>
	);
}
