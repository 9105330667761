import { Box } from '@mui/material';
import React from 'react';

type Props = { color?: string };
const FilterSvg = ({ color = 'white' }: Props) => {
	return (
		<Box
			sx={{
				width: '35px',
				height: '35px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				ml: 1,
			}}
		>
			<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.5 19.57'>
				<g id='Capa_2' data-name='Capa 2'>
					<g id='Capa_1-2' data-name='Capa 1'>
						<path
							style={{
								fill: 'none',
								stroke: '#19255b',
								strokeLinecap: 'round',
								strokeMiterlimit: 10,
								strokeWidth: '2px',
							}}
							d='M19.5,9.79H7.14m-4.36,0H1m1.78,0a2.15,2.15,0,0,1,.64-1.54A2.18,2.18,0,0,1,7.14,9.79a2.22,2.22,0,0,1-.63,1.54A2.19,2.19,0,0,1,2.78,9.79Zm16.72,6.6H13.75m0,0a2.2,2.2,0,0,1-.64,1.55,2.19,2.19,0,0,1-3.72-1.55m4.36,0a2.18,2.18,0,0,0-4.36,0m0,0H1M19.5,3.18H16.39M12,3.18H1m11,0A2.19,2.19,0,0,1,14.21,1a2.21,2.21,0,0,1,.84.17,2.37,2.37,0,0,1,.71.47,2.15,2.15,0,0,1,.47.71,2.12,2.12,0,0,1,.16.83,2.12,2.12,0,0,1-.16.83,2.15,2.15,0,0,1-.47.71,2.37,2.37,0,0,1-.71.47,2.17,2.17,0,0,1-2.38-.47A2.17,2.17,0,0,1,12,3.18Z'
						/>
					</g>
				</g>
			</svg>
		</Box>
	);
};

export default FilterSvg;
