'use client';
import { decryptAES, encryptAES } from '@negotium/common';
import { usePathname } from 'next/navigation';
import { createContext, useContext, useEffect, useState } from 'react';

interface ContextCartShopType {
	cartShopId: string;
	cartShopIdWholesaler: string;
	countProductCartShop: number;
	saveCartShopIdLocalStorage: (cartId: string) => void;
	saveCartShopIdLocalStorageEncrypted: (cartId: string) => void;
	saveCartShopIdLocalStorageWholesaler: (cartId: string) => void;
	setCartShopId: (cartId: string) => void;
	setCountProductCartShop: (count: number) => void;
	getCartShopIdLocalStorage: () => void;
	getCartShopIdLocalStorageWholesaler: () => void;
	changeCountProducts: () => void;
	discountProducts: () => void;
	clearCartShopLocalStorage: () => void;
	clearCartShopLocalStorageWholesaler: () => void;
	getCartShopIdLocalStorageEncrypt: () => string;
}
const ContextCartShop = createContext<ContextCartShopType>({
	cartShopId: '',
	cartShopIdWholesaler: '',
	countProductCartShop: 0,
	saveCartShopIdLocalStorage: () => {},
	saveCartShopIdLocalStorageEncrypted: () => {},
	saveCartShopIdLocalStorageWholesaler: () => {},
	setCartShopId: () => {},
	setCountProductCartShop: () => {},
	getCartShopIdLocalStorage: () => {},
	getCartShopIdLocalStorageWholesaler: () => {},
	changeCountProducts: () => {},
	clearCartShopLocalStorage: () => {},
	clearCartShopLocalStorageWholesaler: () => {},
	discountProducts: () => {},
	getCartShopIdLocalStorageEncrypt: () => '',
});

export const CartShopProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [cartShopId, setCartShopId] = useState<string>('');
	const [cartShopIdWholesaler, setCartShopIdWholesaler] = useState<string>('');
	const [countProductCartShop, setCountProductCartShop] = useState<number>(0);
	const pathname = usePathname();

	function changeCountProducts() {
		setCountProductCartShop(countProductCartShop + 1);
	}
	function discountProducts() {
		setCountProductCartShop(countProductCartShop - 1);
	}

	function saveCartShopIdLocalStorage(cartShopId: string) {
		const encryptedCartShop = encryptAES(
			cartShopId,
			process.env.NEXT_PUBLIC_ENCRYPT_KEY || ''
		);
		if (
			typeof window !== 'undefined' &&
			typeof window.localStorage !== 'undefined'
		) {
			localStorage.setItem('cartShopUser', encryptedCartShop);
			setCartShopId(cartShopId);
		}
	}
	function getCartShopIdLocalStorage() {
		if (
			typeof window !== 'undefined' &&
			typeof window.localStorage !== 'undefined'
		) {
			const cartShopUserLocal = localStorage.getItem('cartShopUser');

			if (cartShopUserLocal) {
				const decryptedCartShop = decryptAES(
					cartShopUserLocal,
					process.env.NEXT_PUBLIC_ENCRYPT_KEY || ''
				);
				if (decryptedCartShop) {
					setCartShopId(decryptedCartShop as string);
				}
			}
		}
	}
	function getCartShopIdLocalStorageEncrypt() {
		if (
			typeof window !== 'undefined' &&
			typeof window.localStorage !== 'undefined'
		) {
			const cartShopUserLocalEncrypt = localStorage.getItem('cartShopUser');

			if (cartShopUserLocalEncrypt) {
				return cartShopUserLocalEncrypt;
			} else {
				return '';
			}
		}
		return '';
	}
	function saveCartShopIdLocalStorageEncrypted(cartShopIdEncrypted: string) {
		if (
			typeof window !== 'undefined' &&
			typeof window.localStorage !== 'undefined'
		) {
			localStorage.setItem('cartShopUser', cartShopIdEncrypted);
			setCartShopId(cartShopId);
		}
	}

	function clearCartShopLocalStorage() {
		if (
			typeof window !== 'undefined' &&
			typeof window.localStorage !== 'undefined'
		) {
			localStorage.removeItem('cartShopUser');
			setCartShopId('');
			setCountProductCartShop(0);
		}
	}
	function saveCartShopIdLocalStorageWholesaler(cartShopIdWholesaler: string) {
		const encryptedCartShopWholesaler = encryptAES(
			cartShopIdWholesaler,
			process.env.NEXT_PUBLIC_ENCRYPT_KEY || ''
		);
		if (
			typeof window !== 'undefined' &&
			typeof window.localStorage !== 'undefined'
		) {
			localStorage.setItem(
				'cartShopUserWholesaler',
				encryptedCartShopWholesaler
			);
			setCartShopIdWholesaler(cartShopIdWholesaler);
		}
	}
	function getCartShopIdLocalStorageWholesaler() {
		if (
			typeof window !== 'undefined' &&
			typeof window.localStorage !== 'undefined'
		) {
			const cartShopUserLocalWholesaler = localStorage.getItem(
				'cartShopUserWholesaler'
			);

			if (cartShopUserLocalWholesaler) {
				const decryptedCartShopWholesaler = decryptAES(
					cartShopUserLocalWholesaler,
					process.env.NEXT_PUBLIC_ENCRYPT_KEY || ''
				);
				if (decryptedCartShopWholesaler) {
					setCartShopIdWholesaler(decryptedCartShopWholesaler as string);
				}
			}
		}
	}

	function clearCartShopLocalStorageWholesaler() {
		if (
			typeof window !== 'undefined' &&
			typeof window.localStorage !== 'undefined'
		) {
			localStorage.removeItem('cartShopUserWholesaler');
			setCartShopIdWholesaler('');
		}
	}
	useEffect(() => {
		getCartShopIdLocalStorage();
	}, []);
	useEffect(() => {
		if (pathname.includes('wholesaler')) {
			getCartShopIdLocalStorageWholesaler();
		}
	}, [pathname]);

	return (
		<ContextCartShop.Provider
			value={{
				cartShopId,
				countProductCartShop,
				cartShopIdWholesaler,
				saveCartShopIdLocalStorageWholesaler,
				getCartShopIdLocalStorageWholesaler,
				clearCartShopLocalStorageWholesaler,
				setCartShopId,
				saveCartShopIdLocalStorage,
				setCountProductCartShop,
				changeCountProducts,
				getCartShopIdLocalStorage,
				clearCartShopLocalStorage,
				discountProducts,
				getCartShopIdLocalStorageEncrypt,
				saveCartShopIdLocalStorageEncrypted,
			}}
		>
			{children}
		</ContextCartShop.Provider>
	);
};

export const useContextCartShop = () => useContext(ContextCartShop);

export default ContextCartShop;
