import React, { useEffect, useRef, useState } from 'react';
import {
	Box,
	Button,
	CircularProgress,
	IconButton,
	Tooltip,
} from '@mui/material';
import { Facebook, WhatsApp, CopyAll } from '@mui/icons-material';
import { useClientTranslation } from '@negotium/common';

const ShareButtons = ({
	lng,
	disabled,
	text = 'Mi carrito de compra',
	elementId,
	element,
}: {
	lng: 'es' | 'en';
	disabled: boolean;
	text?: string;
	elementId?: string;
	element?: string;
}) => {
	const { t } = useClientTranslation(lng, ['common']);
	const [showShareButtons, setShowShareButtons] = useState<boolean>(false);
	const ref = useRef<HTMLDivElement>(null);

	const [url, setUrl] = useState<string>(
		`${process.env.NEXT_PUBLIC_DOMAIN}/${lng}`
	);

	useEffect(() => {
		if (elementId) {
			if (element === 'cartShop') {
				setUrl(
					`${process.env.NEXT_PUBLIC_DOMAIN}/${lng}?${element}=${elementId}`
				);
			}
			if (element === 'product') {
				setUrl(`${process.env.NEXT_PUBLIC_DOMAIN}/${lng}/product/${elementId}`);
			}
		}
	}, [elementId]);

	const handleHide = () => {
		setShowShareButtons(false);
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				handleHide();
			}
		};

		if (showShareButtons) {
			document.addEventListener('click', handleClickOutside);
		} else {
			document.removeEventListener('click', handleClickOutside);
		}

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [showShareButtons]);

	return (
		<Box
			ref={ref}
			sx={{
				position: 'relative',
			}}
		>
			<Button
				color='primary'
				variant='contained'
				size='medium'
				title={t('share', { ns: 'common' })}
				disabled={disabled}
				onClick={() => setShowShareButtons(true)}
				sx={{
					marginLeft: { md: 1, xs: 0 },
				}}
			>
				{disabled ? (
					<CircularProgress color='secondary' />
				) : (
					t('share', { ns: 'common' })
				)}
			</Button>

			{showShareButtons && (
				<Box
					sx={{
						display: 'flex',
						gap: '10px',
						position: 'absolute',
						zIndex: 99,
						bottom: '-50px',
						right: '00px',
						background: (theme) => theme.palette.primary?.light,
						borderRadius: '10px',
						padding: '10px',
						boxShadow: 3,
					}}
				>
					<Tooltip title={t('shareFacebook')}>
						<IconButton
							color='primary'
							component='a'
							href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
								url
							)}`}
							target='_blank'
							rel='noopener noreferrer'
							onClick={handleHide}
						>
							<Facebook />
						</IconButton>
					</Tooltip>

					<Tooltip title={t('shareWhatsapp')}>
						<IconButton
							color='primary'
							component='a'
							href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
								text
							)} ${encodeURIComponent(url)}`}
							target='_blank'
							rel='noopener noreferrer'
							onClick={handleHide}
						>
							<WhatsApp />
						</IconButton>
					</Tooltip>

					<Tooltip title={t('shareCopyLink')}>
						<IconButton
							color='primary'
							onClick={() => {
								navigator.clipboard.writeText(url); // Copiar al portapapeles
								handleHide();
							}}
						>
							<CopyAll />
						</IconButton>
					</Tooltip>
				</Box>
			)}
		</Box>
	);
};

export default ShareButtons;
