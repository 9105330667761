import { type Theme, createTheme } from '@mui/material/styles';

const colorBody = '#ffffff';
const colorPaper = '#e3e3e3';
declare module '@mui/material/styles' {
	interface PaletteColor {
		percent35?: string;
		percent50?: string;
	}
	interface SimplePaletteColorOptions {
		percent35?: string;
		percent50?: string;
	}
}

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		blue: true;

		ButtonAction: true;
		ButtonAdd: true;
		ButtonEdit: true;
		ButtonDelete: true;
		ButtonActionText: true;
		ButtonNavbarActive: true;
		ButtonNavbarAction: true;
		ButtonAcceptDialog: true;
		ButtonCancelDialog: true;
	}
}
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		TitleSection: true;
		BodySection: true;
		ExplicationText: true;
		ExplicationTextBold: true;
		TitleCard: true;
		MenuLink: true;
		MenuLinkPrimary: true;
		Subtitle: true;
		Body1: true;
	}
}

const PRIMARY_COLOR_PALETTE = {
	main: '#f89b35',
	light: '#eee3d2',
	dark: 'rgb(245,190,67)',
	contrastText: '#ffffff',
};
const SECONDARY_COLOR_PALETTE = {
	main: '#b71f33',
	light: '#f1688180',
	dark: 'rgb(190,20,10)',
	contrastText: '#ffffff',
};

const WHITE_COLOR_PALETTE = {
	main: '#ffffff',
	light: 'rgba(217,217,217,0.7)',
	dark: '#626363',
	contrastText: '#f89b35',
};

export const ThemePalette = createTheme({
	palette: {
		primary: PRIMARY_COLOR_PALETTE,
		secondary: SECONDARY_COLOR_PALETTE,
		white: WHITE_COLOR_PALETTE,
		background: {
			default: colorBody,
			paper: colorPaper,
		},
	},
});

const extendTheme = (theme: Theme) => {
	theme.typography.h1 = {
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: 900,
		fontSize: '2rem',
		lineHeight: '2.8rem',
	};
	theme.typography.h2 = {
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: '24px',
	};
	theme.typography.h3 = {
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: '24px',
	};
	theme.typography.h4 = {
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: 700,
		fontSize: '16px',
		lineHeight: '24px',
	};
	theme.typography.h5 = {
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: 700,
		fontSize: '18px',
		lineHeight: '24px',
	};
	theme.typography.h6 = {
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: 700,
		fontSize: '16px',
		lineHeight: '24px',
	};

	theme.typography.body1 = {
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: '16px',
		lineHeight: '24px',
	};

	return theme;
};

export const ThemeBasic = extendTheme(
	createTheme(ThemePalette, {
		components: {
			MuiCssBaseline: {
				styleOverrides: () => ({
					'.cls-1': { fill: '#242c57' },
					'.ListItemButton': {
						color: `${ThemePalette.palette.white?.main || 'white'} !important`,
						margin: '0 5px 0 5px !important',
						fontFamily: 'Lato',
						'&.Mui-selected': {
							fontWeight: 900,
						},
					},
					'.listItemCategoricFilter': {
						background: 'transparent',
					},
					'.slick-slide': {
						'&>*': {
							'&:nth-child(1)': {
								display: 'flex',
							},
						},
					},
					'.slick-dots': {
						position: 'absolute',
						bottom: '15%',
						zIndex: '999',
						width: 'max-content',
						left: '5.4%',
						li: {
							width: 'auto',
							height: 'auto',
							padding: '0',
							button: {
								width: 'auto',
								height: 'auto',
								padding: '0',
								marginRight: '5px',
								':before': {
									fontSize: '1.4rem !important',

									color: `${
										ThemePalette.palette.white?.main || 'white'
									} !important`,
									// change fontsize media query maxwidth 768px
									'@media (max-width: 768px)': {
										fontSize: '0.7rem !important',
									},
								},
							},
							'@media (min-width: 768px)': {
								marginRight: '15px',
							},
						},
						'.slick-active': {
							button: {
								':before': {
									fontSize: '1.4rem !important',
									color: `${
										ThemePalette.palette.white?.main || 'white'
									} !important`,
									opacity: `${1} !important`,
									'@media (max-width: 768px)': {
										fontSize: '0.7rem !important',
									},
								},
							},
						},
					},
					'.sliderProductImages': {
						'.slick-dots': {
							position: 'relative !important',
							left: 0,
							width: '100%',
							li: {
								width: 'auto',
								height: 'auto',
								padding: '0',
								button: {
									width: 'auto',
									height: 'auto',
									padding: '0',
									':before': {
										fontSize: '1.4rem !important',

										color: `${
											ThemePalette.palette.white?.dark || 'white'
										} !important`,
										// change fontsize media query maxwidth 768px
										'@media (max-width: 768px)': {
											fontSize: '0.7rem !important',
										},
									},
								},
							},
							'.slick-active': {
								button: {
									':before': {
										fontSize: '1.4rem !important',
										color: `${ThemePalette.palette.secondary?.main} !important`,
										opacity: `${1} !important`,
										'@media (max-width: 768px)': {
											fontSize: '0.7rem !important',
										},
									},
								},
							},
						},
					},
					'@keyframes spin': {
						'0%': {
							transform: 'scale(0.8)',
						},
						'100%': {
							transform: 'scale(1)',
						},
					},
				}),
			},
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: '8px',
						fontWeight: 'bold',
						transition: 'ease all .4s',
						'&:hover': {
							transition: 'ease all .4s',
						},
						'&:disabled': {
							cursor: 'not-allowed',
							color: ThemePalette.palette.primary.dark,
							background: ThemePalette.palette.primary.light,
							border: 'none',
						},
					},
				},
				variants: [
					{
						props: { variant: 'ButtonActionText' },
						style: {
							color: ThemePalette.palette.secondary.main,
							fontSize: '14px',
							textTransform: 'none',
							'&:hover': {
								fontWeight: 'bold',
								background: 'transparent',
								textDecoration: 'underline',
							},
						},
					},
					{
						props: { variant: 'ButtonAcceptDialog' },
						style: {
							color: ThemePalette.palette.white?.main || 'white',
							background: ThemePalette.palette.primary.main,
							borderRadius: '10px',
							width: '200px',
							textTransform: 'none',
							'&:hover': {
								background: ThemePalette.palette.primary.light,
							},
						},
					},
					{
						props: { variant: 'ButtonCancelDialog' },
						style: {
							border: '2px solid',
							borderColor: ThemePalette.palette.secondary?.main,
							color: ThemePalette.palette.secondary?.main || 'white',
							background: ThemePalette.palette.white?.main || 'white',
							borderRadius: '10px',
							width: '200px',
							textTransform: 'none',
							'&:hover': {
								borderColor: ThemePalette.palette.primary?.main,
							},
						},
					},
					{
						props: { variant: 'ButtonNavbarActive' },
						style: {
							color: ThemePalette.palette.secondary.main,
							background: ThemePalette.palette.primary.main,

							'&:hover': {
								color: ThemePalette.palette.primary.main,
								background: 'transparent',
								borderColor: ThemePalette.palette.primary.main,
								transform: 'scale(1.03)',
							},
						},
					},
					{
						props: { variant: 'ButtonAction' },
						style: {
							border: `2px solid ${ThemePalette.palette.primary.main}`,
							borderRadius: '5px',
							textTransform: 'Capitalize',
							fontWeight: 'bold',
							color: ThemePalette.palette.secondary.main,
							background: ThemePalette.palette.primary.main,
							transition: 'ease all .4s',
							'&:hover': {
								color: ThemePalette.palette.primary.main,
								background: 'transparent',
								transform: 'scale(1.03)',
							},
						},
					},
					{
						props: { variant: 'ButtonAdd' },
						style: {
							color: ThemePalette.palette.white?.main || 'white',
							background: ThemePalette.palette.secondary.main,

							'&:hover': {
								color: ThemePalette.palette.secondary.main,
								transform: 'scale(1.03)',
							},
						},
					},
					{
						props: { variant: 'ButtonEdit' },
						style: {
							color: ThemePalette.palette.white?.main || 'white',
							background: ThemePalette.palette.primary.main,
							borderColor: ThemePalette.palette.primary.main,
							padding: '0px',
							'&:hover': {
								color: ThemePalette.palette.white?.main || 'white',
								transform: 'scale(1.03)',
							},
						},
					},
					{
						props: { variant: 'ButtonDelete' },
						style: {
							color: ThemePalette.palette.white?.main || 'white',
							background: ThemePalette.palette.error.main,
							borderColor: ThemePalette.palette.error.main,
							padding: '0px',

							'&:hover': {
								color: ThemePalette.palette.primary.main,
								background: 'transparent',
								transform: 'scale(1.03)',
							},
						},
					},
					{
						props: { variant: 'ButtonNavbarAction' },
						style: {
							border: `none`,
							height: '35px',
							minWidth: '35px !important',
							padding: 1,
							color: ThemePalette.palette.primary.main,
							svg: {
								color: `${
									ThemePalette.palette.white?.main || 'white'
								} !important`,
								width: '2rem',
								height: '2rem',
							},
							'&:hover': {
								backgroundColor: ThemePalette.palette.white?.main || 'white',
								color: ThemePalette.palette.primary.main,
								borderRadius: '50%',
								cursor: 'pointer',
								svg: {
									color: `${ThemePalette.palette.secondary?.main} !important`,
								},
							},
						},
					},
				],
			},
			MuiTypography: {
				styleOverrides: {
					root: {},
				},
				defaultProps: {
					variantMapping: {
						TitleSection: 'h1',
						Subtitle: 'h6',
						BodySection: 'p',
						Body1: 'p',
						ExplicationText: 'p',
						ExplicationTextBold: 'p',
					},
				},

				variants: [
					{
						props: { variant: 'TitleSection' },
						style: {
							fontSize: 'clamp(1rem,3vw, 1.5rem)',
							color: ThemePalette.palette.secondary.main,
							fontFamily: 'Roboto',
							fontWeight: 900,
						},
					},
					{
						props: { variant: 'TitleCard' },
						style: {
							fontSize: 'clamp(1rem,1.6vw, 2rem)',
							color: ThemePalette.palette.secondary.main,
						},
					},
					{
						props: { variant: 'MenuLink' },
						style: {
							fontSize: 'clamp(1rem,1.4vw, 1.8rem)',
							color: ThemePalette.palette.secondary.main,
						},
					},
					{
						props: { variant: 'MenuLinkPrimary' },
						style: {
							fontSize: 'clamp(1rem,1.4vw, 1.8rem)',
							color: ThemePalette.palette.primary.main,
						},
					},
					{
						props: { variant: 'BodySection' },
						style: {
							fontSize: 'clamp(0.8rem,3vw, 1.3rem)',
							color: ThemePalette.palette.secondary.main,

							margin: '5px 0',
							a: {
								color: ThemePalette.palette.secondary.main,
							},
						},
					},
					{
						props: { variant: 'ExplicationText' },
						style: {
							fontSize: 'clamp(11px,3vw, 14px)',
						},
					},
					{
						props: { variant: 'ExplicationTextBold' },
						style: {
							fontSize: 'clamp(11px,3vw, 14px)',
							fontWeight: 900,
							fontFamily: 'Roboto',
							color: ThemePalette.palette.secondary.main,
						},
					},
					{
						props: { variant: 'Subtitle' },
						style: {
							fontSize: 'clamp(0.8rem,1.4vw, 1.3rem)',
							color: ThemePalette.palette.secondary.main,
							lineHeight: '2rem',
							margin: '5px 0',
							a: {
								color: ThemePalette.palette.secondary.main,
							},
						},
					},
					{
						props: { variant: 'Body1' },
						style: {
							fontSize: 'clamp(0.7rem,1.4vw, 1rem)',
							color: ThemePalette.palette.secondary.main,
							lineHeight: '2rem',
							letterSpacing: 1.8,
							margin: '5px 0',
							a: {
								color: ThemePalette.palette.secondary.main,
							},
						},
					},
				],
			},
		},
	})
);
export default ThemeBasic;
