import React from 'react';
import {
	Avatar,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { usePathname, useRouter } from 'next/navigation';

const LanguageSelect = ({ lng }: { lng: 'es' | 'en' }) => {
	const pathname = usePathname();
	const router = useRouter();

	const handleChange = (event: SelectChangeEvent) => {
		const newUrl = event.target.value === 'en' ? '/en' : '/es';
		router.push(
			newUrl + pathname.replace(event.target.value === 'en' ? '/es' : '/en', '')
		);
	};
	return (
		<Select
			value={lng}
			onChange={handleChange}
			IconComponent={KeyboardArrowDownIcon}
			sx={{
				'& .MuiSelect-select': {
					minHeight: '30px !important',
					py: '4.5px !important',
					backgroundColor: 'white !important',
					color: (theme) => `${theme.palette.secondary.main} !important`,
					borderColor: (theme) => `${theme.palette.secondary.main} !important`,
					display: 'flex',
					alignItems: 'center',
					img: {
						mr: 1,
					},
					borderRadius: '10px',
				},
				'& .MuiSelect-icon': {
					color: (theme) => `${theme.palette.secondary.main} !important`,
				},
			}}
		>
			<MenuItem
				value='en'
				title='en'
				sx={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Avatar
					sx={{ width: 30, height: 25 }}
					alt='en'
					src={`/static/flags/en.png`}
				/>
				<Typography variant='ExplicationText' ml={0.7} fontWeight='bold'>
					EN
				</Typography>
			</MenuItem>
			<MenuItem
				value='es'
				title='es'
				sx={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Avatar
					sx={{ width: 30, height: 25 }}
					alt='es'
					src={`/static/flags/es.png`}
				/>
				<Typography variant='ExplicationText' ml={0.7} fontWeight='bold'>
					ES
				</Typography>
			</MenuItem>
		</Select>
	);
};

export default LanguageSelect;
