import React from 'react';

type Props = { color?: string };

const WishListSvg = ({ color = 'white' }: Props) => {
	return (
		<svg
			version='1.1'
			id='Capa_1'
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			x='0px'
			y='0px'
			viewBox='0 0 18 18'
			xmlSpace='preserve'
		>
			<path
				d='M9,4.2c0,0,0,0,0.6-0.8c0.7-1,1.9-1.7,3.2-1.6c1.6,0.1,3,1.4,3.2,3c0.1,0.9-0.1,1.8-0.6,2.5c-0.5,0.8-4.5,5-5.9,6.5
	c-0.3,0.3-0.8,0.3-1.1,0C7,12.4,3.1,8.2,2.5,7.4C2.1,6.7,1.8,5.8,2,4.8C2.2,3.4,3.4,2.2,4.8,2c1.5-0.3,2.8,0.4,3.7,1.5
	C9,4.2,9,4.2,9,4.2z'
				fill={color}
			/>
		</svg>
	);
};

export default WishListSvg;
