import React from 'react';

type Props = { color?: string; width?: string; height?: string };

const WholesalerSvg = ({
	color = 'white',
	width = '21px',
	height = '21px',
}: Props) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 14 12'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M13.25 10.3337V3.79022C13.25 3.2864 12.9336 2.83788 12.4527 2.64741L7.39016 0.681303C7.08641 0.564566 6.75102 0.564566 6.44727 0.681303L1.38477 2.64741C0.910156 2.83788 0.59375 3.29255 0.59375 3.79022V10.3337C0.59375 11.0095 1.16328 11.5625 1.85938 11.5625H3.75781V6.03281H10.0859V11.5625H11.9844C12.6805 11.5625 13.25 11.0095 13.25 10.3337ZM6.28906 10.3337H5.02344V11.5625H6.28906V10.3337ZM7.55469 8.49045H6.28906V9.71927H7.55469V8.49045ZM8.82031 10.3337H7.55469V11.5625H8.82031V10.3337Z'
				fill={color}
			/>
		</svg>
	);
};

export default WholesalerSvg;
