import React from 'react';

type Props = { color?: string };

const ArrowSvg = ({ color = 'white' }: Props) => {
	return (
		<svg
			id='Capa_1'
			data-name='Capa 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 16 16'
		>
			<path
				fill={color}
				d='M9,9.58l6.66-7.13A1.61,1.61,0,0,0,15.9,2a1.45,1.45,0,0,0,.1-.55,1.48,1.48,0,0,0-.39-1,1.35,1.35,0,0,0-.44-.31A1.26,1.26,0,0,0,14.66,0a1.28,1.28,0,0,0-.94.42L8,6.55,2.29.42A1.47,1.47,0,0,0,1.85.11a1.24,1.24,0,0,0-1,0A1.47,1.47,0,0,0,.39.42,1.49,1.49,0,0,0,.1.89a1.44,1.44,0,0,0-.1.54A1.45,1.45,0,0,0,.1,2a1.61,1.61,0,0,0,.29.47L7.06,9.58a1.31,1.31,0,0,0,.43.31A1.3,1.3,0,0,0,8,10a1.26,1.26,0,0,0,.51-.11A1.35,1.35,0,0,0,9,9.58Z'
			/>
		</svg>
	);
};

export default ArrowSvg;
