export function formatCurrency(number: number, currency = 'USD') {
	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency,
	}).format(number);
}

export function getCurrencySymbol(currencyCode: string): string {
	const currencyPart = new Intl.NumberFormat('en', {
		style: 'currency',
		currency: currencyCode,
	})
		.formatToParts(1)
		.find((part) => part.type === 'currency');

	return currencyPart?.value || '$'; // Fallback to a default symbol if undefined
}

export const numWithComma = (number: number | string) =>
	Number(number)
		.toFixed(2)
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
