'use client';
import { useClientTranslation } from '@negotium/common';
import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import React from 'react';

import { logout } from '@negotium/libCustomer';
import { useRouter } from 'next/navigation';

type ChangePasswordProps = {
	lng: 'es' | 'en';
};

export default function CloseSession({ lng }: ChangePasswordProps) {
	const { t } = useClientTranslation(lng, ['common']);
	const router = useRouter();

	const handleLogout = async () => {
		await logout();
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Paper
					elevation={2}
					sx={{
						background: 'white',
						mt: 2,
						p: { md: 4, xs: 1 },
						borderRadius: 5,
						height: { md: '70vh' },
						overflow: { md: 'auto' },
					}}
				>
					<Container maxWidth='xl' disableGutters>
						<Grid container>
							<Grid item xs={12} display='flex' justifyContent='center'>
								<Typography
									variant='ExplicationTextBold'
									textAlign='center'
									fontSize='clamp(1rem, 3vw, 1.3rem)'
									mt={2}
									mb={4}
									width='65%'
								>
									{t('sureCloseSession')}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										width: '70%',
										margin: 'auto',
										mb: 4,
									}}
								>
									<Button
										variant='contained'
										color='error'
										title={t('cancel')}
										sx={{
											textTransform: 'capitalize',
										}}
										onClick={() => router.push('/account')}
									>
										{t('cancel')}
									</Button>
									<Button
										variant='contained'
										color='secondary'
										title={t('confirm')}
										sx={{
											textTransform: 'capitalize',
											ml: 1,
										}}
										onClick={() => handleLogout()}
									>
										{t('confirm')}
									</Button>
								</Box>
							</Grid>
						</Grid>
					</Container>
				</Paper>
			</Grid>
		</Grid>
	);
}
