import React from 'react';

type Props = { color?: string };

const CloseIconSvg = ({ color = 'white' }: Props) => {
	return (
		<svg
			id='Capa_1'
			data-name='Capa 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 14 14'
		>
			<path
				d='M13.41,12a1.07,1.07,0,0,1,0,.86,1,1,0,0,1-.27.37.89.89,0,0,1-.31.21,1.07,1.07,0,0,1-.86,0,1,1,0,0,1-.37-.27L7,8.56,2.37,13.17a1.11,1.11,0,0,1-1.56,0A1,1,0,0,1,.5,12.4a1,1,0,0,1,.34-.79L5.44,7,.83,2.37A1,1,0,0,1,.5,1.6,1,1,0,0,1,.84.81a1.13,1.13,0,0,1,1.55,0L7,5.44,11.63.83a1.11,1.11,0,0,1,1.56,0,1,1,0,0,1,.31.76,1,1,0,0,1-.34.79L8.56,7l4.61,4.63A.87.87,0,0,1,13.41,12Z'
				fill={color}
			/>
		</svg>
	);
};

export default CloseIconSvg;
