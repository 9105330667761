'use client';
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormGroup,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Slide,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useNotification } from '../ErrorContext';
import EditIcon from '@mui/icons-material/Edit';
import { AxiosError } from 'axios';
import { LoadingButton } from '@mui/lab';
import useRegisterFormData from './hooks/useRegisterFormData';
import AddIcon from '@mui/icons-material/Add';
import { PROVINCES, useClientTranslation } from '@negotium/common';
import { IRecipients } from '@negotium/models';
import {
	createRecipient,
	getRecipientById,
	updateRecipient,
} from '@negotium/libCustomer';
import React, { useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export default function RecipientsWidget({
	onCallBack,
	type,
	id,
	lng = 'es',
}: {
	type: string;
	id?: string;
	lng: 'es' | 'en';
	onCallBack: () => void;
}) {
	const { t } = useClientTranslation(lng, ['recipients']);

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const [open, setOpen] = useState<boolean>(false);
	const [loading, setLoading] = useState(false);
	const { setErrorNotification, setSuccessNotification } = useNotification();
	// const [provinceSelected, setProvinceSelected] = useState<string>('');
	// const [municipalitySelected, setMunicipalitySelected] = useState<string>('');
	const [municipalitiesProvince, setMunicipalitiesProvince] = useState<
		Array<{
			label: string;
			value: string;
		}>
	>([]);

	const initialValues = {
		name: '',
		lastName: '',
		email: '',
		phone: '',
		ci: '',
		address: '',
		province: 'Ciego de Ávila',
		provinceId: '9',
		municipality: '',
		municipalityId: '',
		instructionDelivery: '',
		geo: '',
	};

	const onSubmit = async (values: Partial<IRecipients>): Promise<void> => {
		try {
			setLoading(true);
			if (id) {
				await updateRecipient(values);
				setSuccessNotification(t('editRecipientsSuccess'));
				setLoading(false);
				setOpen(false);
				onCallBack();
			} else {
				await createRecipient(values);
				setSuccessNotification(t('addRecipientsSuccess'));
				setLoading(false);
				setOpen(false);
				onCallBack();
			}
		} catch (err) {
			if (err instanceof AxiosError) {
				setErrorNotification(t(`${err?.response?.data.message}`));
			}
			setLoading(false);
		}
	};

	const { formik, isLoading } = useRegisterFormData(
		initialValues,
		onSubmit,
		lng
	);

	useEffect(() => {
		if (id) {
			setLoading(true);
			getRecipientById(id)
				.then((result) => {
					if (result.data) {
						formik.setValues(result.data);
						const selected = PROVINCES.find(
							(province) => province.value === result.data?.provinceId
						);
						if (selected) {
							setMunicipalitiesProvince(selected.municipalities);
						}
					}
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
					setErrorNotification(err.message);
				});
		}
	}, [id]);

	// function handlerSelectProvince(e: SelectChangeEvent<string>) {
	// 	const {
	// 		target: { value },
	// 	} = e;

	// 	const selected = PROVINCES.find((province) => province.value === '9');
	// 	if (selected) {
	// 		// formik.setFieldValue('province', selected?.label);
	// 		// formik.setFieldValue('provinceId', value);
	// 		// formik.setFieldValue('municipality', '');
	// 		// formik.setFieldValue('municipalityId', '');
	// 		setMunicipalitiesProvince(selected.municipalities);
	// 	}
	// }

	useEffect(() => {
		if (formik.values.provinceId) {
			const selected = PROVINCES.find(
				(province) => province.value === formik.values.provinceId
			);
			if (selected) {
				setMunicipalitiesProvince(selected.municipalities);
			}
		}
	}, [formik.values.provinceId]);

	function handlerSelectMunicipality(e: SelectChangeEvent<string>) {
		const {
			target: { value },
		} = e;
		const selected = municipalitiesProvince.find(
			(municipality) => municipality.value === value
		);
		if (selected) {
			formik.setFieldValue('municipality', selected?.label);
			formik.setFieldValue('municipalityId', value);
		}
	}

	return (
		<>
			{type === 'Add' && (
				<Button
					variant='ButtonAdd'
					onClick={() => setOpen(true)}
					startIcon={<AddIcon />}
					sx={{
						textTransform: 'none',
					}}
				>
					{t('addRecipients')}
				</Button>
			)}
			{type === 'Edit' && (
				<Button
					variant='ButtonEdit'
					title={t('editRecipients')}
					onClick={() => setOpen(true)}
					sx={{
						textTransform: 'none',
					}}
				>
					<EditIcon
						sx={{
							color: 'white.main',
						}}
					/>
				</Button>
			)}
			{open && (
				<Dialog
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => setOpen(false)}
					aria-describedby='alert-dialog-slide-description'
					fullScreen={fullScreen}
					maxWidth='md'
					PaperProps={{
						sx: {
							backgroundColor: theme.palette.white?.main || 'white',
							p: 6,
							borderRadius: 6,
						},
					}}
					slotProps={{
						backdrop: {
							sx: {
								backdropFilter: 'blur(10px)',
							},
						},
					}}
				>
					<DialogTitle sx={{ mb: 2 }}>
						<Box
							display='flex'
							justifyContent='space-between'
							alignItems='center'
						>
							<Typography variant='TitleSection'>
								{type === 'Edit' ? t('updateRecipients') : t('newRecipients')}
							</Typography>
							<Button
								onClick={() => setOpen(false)}
								variant='text'
								color='secondary'
								sx={{
									p: 0,
									minWidth: '30px',
									height: '30px',
									borderRadius: '50%',
								}}
							>
								<Close />
							</Button>
						</Box>
					</DialogTitle>

					<DialogContent>
						<form onSubmit={formik.handleSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6}>
									<FormGroup>
										<InputLabel
											sx={{
												color: theme.palette.secondary.main || 'black',
												fontWeight: '600',
												ml: 3,
											}}
										>
											{t('name')}
										</InputLabel>
										<TextField
											color='primary'
											id='name'
											placeholder='Ej. John'
											fullWidth={false}
											margin='dense'
											name='name'
											value={formik.values.name}
											onChange={(e) => formik.handleChange(e)}
											onBlur={formik.handleBlur}
											error={formik.touched.name && Boolean(formik.errors.name)}
											helperText={formik.touched.name && formik.errors.name}
											sx={{
												borderRadius: 4,
												'& .MuiOutlinedInput-notchedOutline': {
													borderColor: (theme) => theme.palette.primary.dark,
													borderWidth: 2,
												},
												'& .MuiInputBase-input': {
													pl: `25px !important`,
												},
												'& > *': {
													borderRadius: `16px !important`,
												},
											}}
										/>
									</FormGroup>
								</Grid>
								<Grid item xs={12} md={6}>
									<FormGroup>
										<InputLabel
											sx={{
												color: theme.palette.secondary.main || 'black',
												fontWeight: '600',
												ml: 3,
											}}
										>
											{t('lastName')}
										</InputLabel>
										<TextField
											color='primary'
											id='lastName'
											placeholder='Ej. Doe'
											fullWidth={false}
											margin='dense'
											name='lastName'
											value={formik.values.lastName}
											onChange={(e) => formik.handleChange(e)}
											onBlur={formik.handleBlur}
											error={
												formik.touched.lastName &&
												Boolean(formik.errors.lastName)
											}
											helperText={
												formik.touched.lastName && formik.errors.lastName
											}
											sx={{
												borderRadius: 4,
												'& .MuiOutlinedInput-notchedOutline': {
													borderColor: (theme) => theme.palette.primary.dark,
													borderWidth: 2,
												},
												'& .MuiInputBase-input': {
													pl: `25px !important`,
												},
												'& > *': {
													borderRadius: `16px !important`,
												},
											}}
										/>
									</FormGroup>
								</Grid>
								<Grid item xs={12} md={6}>
									<FormGroup>
										<InputLabel
											sx={{
												color: theme.palette.secondary.main || 'black',
												fontWeight: '600',
												ml: 3,
											}}
										>
											{t('email')}
										</InputLabel>
										<TextField
											color='primary'
											id='email'
											placeholder='user@NEXT_PUBLIC_DOMAIN.xxx'
											fullWidth={false}
											margin='dense'
											name='email'
											value={formik.values.email}
											onChange={(e) => formik.handleChange(e)}
											onBlur={formik.handleBlur}
											error={
												formik.touched.email && Boolean(formik.errors.email)
											}
											helperText={formik.touched.email && formik.errors.email}
											sx={{
												borderRadius: 4,
												'& .MuiOutlinedInput-notchedOutline': {
													borderColor: (theme) => theme.palette.primary.dark,
													borderWidth: 2,
												},
												'& .MuiInputBase-input': {
													pl: `25px !important`,
												},
												'& > *': {
													borderRadius: `16px !important`,
												},
											}}
										/>
									</FormGroup>
								</Grid>
								<Grid item xs={12} md={3}>
									<FormGroup>
										<InputLabel
											color='secondary'
											sx={{
												color: theme.palette.secondary.main || 'black',
												fontWeight: '600',
												ml: 3,
											}}
										>
											{t('phone')}
										</InputLabel>
										<TextField
											color='primary'
											id='phone'
											placeholder='+53 5XXXXXXX'
											fullWidth={false}
											margin='dense'
											name='phone'
											value={formik.values.phone}
											onChange={(e) => formik.handleChange(e)}
											onBlur={formik.handleBlur}
											error={
												formik.touched.phone && Boolean(formik.errors.phone)
											}
											helperText={formik.touched.phone && formik.errors.phone}
											sx={{
												borderRadius: 4,
												'& .MuiOutlinedInput-notchedOutline': {
													borderColor: (theme) => theme.palette.primary.dark,
													borderWidth: 2,
												},
												'& .MuiInputBase-input': {
													pl: `25px !important`,
												},
												'& > *': {
													borderRadius: `16px !important`,
												},
											}}
										/>
									</FormGroup>
								</Grid>{' '}
								<Grid item xs={12} md={3}>
									<FormGroup>
										<InputLabel
											color='secondary'
											sx={{
												color: theme.palette.secondary.main || 'black',
												fontWeight: '600',
												ml: 3,
											}}
										>
											{t('ci')}
										</InputLabel>

										<TextField
											color='primary'
											id='ci'
											placeholder='87XXXXXXXXX'
											fullWidth={false}
											inputProps={{
												maxLength: 11,
												minLength: 11,
											}}
											margin='dense'
											name='ci'
											value={formik.values.ci}
											onChange={(e) => formik.handleChange(e)}
											onBlur={formik.handleBlur}
											error={formik.touched.ci && Boolean(formik.errors.ci)}
											helperText={formik.touched.ci && formik.errors.ci}
											sx={{
												borderRadius: 4,
												'& .MuiOutlinedInput-notchedOutline': {
													borderColor: (theme) => theme.palette.primary.dark,
													borderWidth: 2,
												},
												'& .MuiInputBase-input': {
													pl: `25px !important`,
												},
												'& > *': {
													borderRadius: `16px !important`,
												},
											}}
										/>
									</FormGroup>
								</Grid>
								<Grid item xs={12} md={6}>
									<FormGroup>
										<InputLabel
											sx={{
												color: theme.palette.secondary.main || 'black',
												fontWeight: '600',
												ml: 3,
												mb: 1,
											}}
										>
											{t('province')}
										</InputLabel>
										<Select
											color='primary'
											placeholder={t('province')}
											labelId='province'
											id='province'
											name='province'
											disabled
											// onChange={handlerSelectProvince}
											value={formik.values.provinceId}
											sx={{
												'& .MuiOutlinedInput-notchedOutline': {
													borderColor: (theme) => theme.palette.primary.dark,
													borderWidth: 2,
													borderRadius: 4,
												},
												'& .MuiInputBase-input': {
													pl: `25px !important`,
												},
											}}
											MenuProps={{
												sx: {
													'&>*': {
														textOverflow: 'inherit',
														whiteSpace: 'wrap',
														overflow: 'inherit',
														'.Mui-selected': {
															background: (theme) =>
																theme.palette.secondary.main + '!important',
															color: (theme) => theme.palette.primary.main,
														},
													},
												},
											}}
										>
											{PROVINCES.map((province) => {
												return (
													<MenuItem
														key={province?.value}
														value={province?.value}
													>
														{province?.label}
													</MenuItem>
												);
											})}
										</Select>
										{formik.errors.province && formik.touched.province && (
											<FormHelperText sx={{ color: '#d32f2f' }}>
												{t('fieldRequired', { ns: 'validations' })}
											</FormHelperText>
										)}
									</FormGroup>
								</Grid>{' '}
								<Grid item xs={12} md={6}>
									<FormGroup>
										<InputLabel
											sx={{
												color: theme.palette.secondary.main || 'black',
												fontWeight: '600',
												ml: 3,
												mb: 1,
											}}
										>
											{t('municipality')}
										</InputLabel>
										<Select
											color='secondary'
											placeholder={t('municipality')}
											labelId='municipality'
											id='municipality'
											name='municipality'
											onChange={handlerSelectMunicipality}
											value={formik.values.municipalityId}
											sx={{
												'& .MuiOutlinedInput-notchedOutline': {
													borderColor: (theme) => theme.palette.primary.dark,
													borderWidth: 2,
													borderRadius: 4,
												},
												'& .MuiInputBase-input': {
													pl: `25px !important`,
												},
											}}
											MenuProps={{
												sx: {
													'&>*': {
														textOverflow: 'inherit',
														whiteSpace: 'wrap',
														overflow: 'inherit',
														'.Mui-selected': {
															background: (theme) =>
																theme.palette.secondary.main + '!important',
															color: (theme) => theme.palette.primary.main,
														},
													},
												},
											}}
										>
											{municipalitiesProvince.map((municipality) => {
												return (
													<MenuItem
														key={municipality?.value}
														value={municipality?.value}
													>
														{municipality?.label}
													</MenuItem>
												);
											})}
										</Select>
										{formik.errors.municipality &&
											formik.touched.municipality && (
												<FormHelperText sx={{ color: '#d32f2f' }}>
													{t('fieldRequired', { ns: 'validations' })}
												</FormHelperText>
											)}
									</FormGroup>
								</Grid>
								<Grid item xs={12}>
									<FormGroup>
										<InputLabel
											color='secondary'
											sx={{
												color: theme.palette.secondary.main || 'black',
												fontWeight: '600',
												ml: 3,
											}}
										>
											{t('address')}
										</InputLabel>

										<TextField
											color='primary'
											id='address'
											placeholder='5ta No.543 ....'
											fullWidth={false}
											multiline
											rows={2}
											margin='dense'
											name='address'
											value={formik.values.address}
											onChange={(e) => formik.handleChange(e)}
											onBlur={formik.handleBlur}
											error={
												formik.touched.address && Boolean(formik.errors.address)
											}
											helperText={
												formik.touched.address && formik.errors.address
											}
											sx={{
												borderRadius: 4,
												'& .MuiOutlinedInput-notchedOutline': {
													borderColor: (theme) => theme.palette.primary.dark,
													borderWidth: 2,
												},
												'& .MuiInputBase-input': {
													pl: `10px !important`,
												},
												'& > *': {
													borderRadius: `16px !important`,
												},
											}}
										/>
									</FormGroup>
								</Grid>
								<Grid item xs={12}>
									<FormGroup>
										<InputLabel
											color='secondary'
											sx={{
												color: theme.palette.secondary.main || 'black',
												fontWeight: '600',
												ml: 3,
											}}
										>
											{t('instructionDelivery')}
										</InputLabel>

										<TextField
											color='primary'
											id='instructionDelivery'
											placeholder=''
											fullWidth={false}
											margin='dense'
											multiline
											rows={2}
											name='instructionDelivery'
											value={formik.values.instructionDelivery}
											onChange={(e) => formik.handleChange(e)}
											onBlur={formik.handleBlur}
											sx={{
												borderRadius: 4,
												'& .MuiOutlinedInput-notchedOutline': {
													borderColor: (theme) => theme.palette.primary.dark,
													borderWidth: 2,
												},
												'& .MuiInputBase-input': {
													pl: `10px !important`,
												},
												'& > *': {
													borderRadius: `16px !important`,
												},
											}}
										/>
									</FormGroup>
								</Grid>
								<Grid item xs={12} display='flex' justifyContent='center'>
									<Button
										color='error'
										variant='contained'
										size='medium'
										sx={{
											width: '200px',
											mr: 1,
										}}
										title={t('cancel', { ns: 'common' })}
										disabled={loading || isLoading}
										onClick={() => setOpen(false)}
									>
										{t('cancel', { ns: 'common' })}
									</Button>
									<LoadingButton
										fullWidth
										type='submit'
										autoFocus
										size='medium'
										loading={loading || isLoading}
										disabled={loading || isLoading}
										loadingPosition='end'
										title={t('save')}
										variant='ButtonAcceptDialog'
										aria-disabled={isLoading || loading}
									>
										{t('save')}
									</LoadingButton>
								</Grid>
							</Grid>
						</form>
					</DialogContent>
				</Dialog>
			)}
		</>
	);
}
