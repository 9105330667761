import { IResultLastExchangeRate } from '@negotium/models';
import commonRequestHeaders from '../common/requestHeaders';

export const getLastExchange = async (): Promise<IResultLastExchangeRate> => {
	try {
		const headers = await commonRequestHeaders();
		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/exchangeRate/public/last`,
			{
				method: 'get',
				credentials: 'include',
				headers: headers,
			}
		);

		if (!promise.ok) {
			if (promise.status === 401) {
				return { status: 401, data: [], message: '' };
			}
		}

		const { data, result, message } = await promise.json();
		if (result === 2) {
			throw new Error(message);
		}

		return {
			status: 200,
			data: data,
			message: message,
		};
	} catch (error) {
		throw new Error((error as Error).message);
	}
};
