import {
	CustomObject,
	IResultCartShopItems,
	IResultCreateCart,
	IResultProductsCartShop,
} from '@negotium/models';
import commonRequestHeaders from '../common/requestHeaders';
import { AxiosError } from 'axios';

export const addProductCartShop = async (
	body: CustomObject | BodyInit | null
): Promise<IResultCreateCart> => {
	try {
		const headers = await commonRequestHeaders();

		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/cartShop/create`,
			{
				method: 'post',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify(body),
			}
		);

		if (!promise.ok) {
			if (promise.status === 401) {
				return { status: 401, data: '', message: '', result: 2 };
			}
		}
		const { data, result, message } = await promise.json();
		if (result === 2) {
			throw new Error(message);
		}

		return {
			status: 200,
			result: result,
			data: data,
			message: message,
		};
	} catch (error) {
		throw new Error((error as AxiosError).message);
	}
};
export const getProductsByCartShopId = async (
	cartId: string
): Promise<IResultCartShopItems> => {
	try {
		const headers = await commonRequestHeaders();

		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/cartShop/allItemsCart/${cartId}`,
			{
				method: 'get',
				credentials: 'include',
				headers: headers,
			}
		);

		if (!promise.ok) {
			if (promise.status === 401) {
				return { data: null, message: '', result: 2 };
			}
		}
		const { data, result, message } = await promise.json();
		if (result === 2) {
			throw new Error(message);
		}

		return {
			result: result,
			data: data,
			message: message,
		};
	} catch (error) {
		throw new Error((error as AxiosError).message);
	}
};

export const deleteProductCartShop = async (
	cartUniqueId: string
): Promise<IResultCreateCart> => {
	try {
		const headers = await commonRequestHeaders();

		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/cartShop/${cartUniqueId}`,
			{
				method: 'delete',
				credentials: 'include',
				headers: headers,
			}
		);

		if (!promise.ok) {
			if (promise.status === 401) {
				return { status: 401, data: '', message: '', result: 2 };
			}
		}
		const { data, result, message } = await promise.json();
		if (result === 2) {
			throw new Error(message);
		}

		return {
			status: 200,
			result: result,
			data: data,
			message: message,
		};
	} catch (error) {
		throw new Error((error as AxiosError).message);
	}
};
export const editAmountProductCartShop = async (
	cartUniqueId: string,
	amount: number
): Promise<IResultCreateCart> => {
	try {
		const headers = await commonRequestHeaders();

		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/cartShop/${cartUniqueId}`,
			{
				method: 'put',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify({ amount }),
			}
		);

		if (!promise.ok) {
			if (promise.status === 401) {
				return { status: 401, data: '', message: '', result: 2 };
			}
		}
		const { data, result, message } = await promise.json();
		if (result === 2) {
			throw new Error(message);
		}

		return {
			status: 200,
			result: result,
			data: data,
			message: message,
		};
	} catch (error) {
		throw new Error((error as AxiosError).message);
	}
};
export const cancelCartShop = async (
	cartId: string
): Promise<IResultCreateCart> => {
	try {
		const headers = await commonRequestHeaders();

		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/cartShop/cancelCartShop/${cartId}`,
			{
				method: 'put',
				credentials: 'include',
				headers: headers,
			}
		);

		if (!promise.ok) {
			if (promise.status === 401) {
				return { status: 401, data: '', message: '', result: 2 };
			}
		}
		const { data, result, message } = await promise.json();
		if (result === 2) {
			throw new Error(message);
		}

		return {
			status: 200,
			result: result,
			data: data,
			message: message,
		};
	} catch (error) {
		throw new Error((error as AxiosError).message);
	}
};
export const getTotalProductsOfCartShop = async (
	cartId: string
): Promise<IResultProductsCartShop> => {
	try {
		const headers = await commonRequestHeaders();

		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/cartShop/cartItemsCount/${cartId}`,
			{
				method: 'put',
				credentials: 'include',
				headers: headers,
			}
		);

		if (!promise.ok) {
			if (promise.status === 401) {
				return { status: 401, data: 0, message: '', result: 2 };
			}
		}
		const { data, result, message } = await promise.json();
		if (result === 2) {
			throw new Error(message);
		}

		return {
			status: 200,
			result: result,
			data: data,
			message: message,
		};
	} catch (error) {
		throw new Error((error as Error).message);
	}
};

export const addedUserAuthCartShop = async (
	cartId: string
): Promise<IResultCreateCart> => {
	try {
		const headers = await commonRequestHeaders();

		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/cartShop/addedUserAuthCartShop/${cartId}`,
			{
				method: 'put',
				credentials: 'include',
				headers: headers,
			}
		);

		if (!promise.ok) {
			if (promise.status === 401) {
				throw new Error(promise.statusText);
			}
		}
		const { data, result, message } = await promise.json();
		if (result === 2) {
			throw new Error(message);
		}

		return {
			status: 200,
			result: result,
			data: data,
			message: message,
		};
	} catch (error) {
		throw new Error((error as AxiosError).message);
	}
};
export const addMethodPaymentToCartShop = async (
	cartId: string,
	methodPaid: string
): Promise<IResultCreateCart> => {
	try {
		const headers = await commonRequestHeaders();

		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/cartShop/methodPaidCartShop/${cartId}`,
			{
				method: 'put',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify({ methodPaid }),
			}
		);

		if (!promise.ok) {
			if (promise.status === 401) {
				throw new Error(promise.statusText);
			}
		}
		const { data, result, message } = await promise.json();
		if (result === 2) {
			throw new Error(message);
		}

		return {
			status: 200,
			result: result,
			data: data,
			message: message,
		};
	} catch (error) {
		throw new Error((error as AxiosError).message);
	}
};
export const paidCartShop = async (
	cartId: string,
	currency: string
): Promise<IResultCreateCart> => {
	try {
		const headers = await commonRequestHeaders();

		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/cartShop/paidCartShop/${cartId}`,
			{
				method: 'put',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify({ currency }),
			}
		);

		if (!promise.ok) {
			if (promise.status === 401) {
				throw new Error(promise.statusText);
			}
		}
		const { data, result, message, isValid = true } = await promise.json();
		if (result === 2) {
			throw new Error(message);
		}

		return {
			status: 200,
			result: result,
			data: data,
			message: message,
			isValid: isValid,
		};
	} catch (error) {
		throw new Error((error as AxiosError).message);
	}
};
export const addRecipientToCart = async (
	cartId: string,
	recipientId: string
): Promise<IResultCreateCart> => {
	try {
		const headers = await commonRequestHeaders();

		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/cartShop/addDestinataryToCart`,
			{
				method: 'POST',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify({ recipientId, cartId }),
			}
		);

		if (!promise.ok) {
			if (promise.status === 401) {
				throw new Error(promise.statusText);
			}
		}
		const { data, result, message } = await promise.json();
		if (result === 2) {
			throw new Error(message);
		}

		return {
			status: 200,
			result: result,
			data: data,
			message: message,
		};
	} catch (error) {
		throw new Error((error as AxiosError).message);
	}
};
