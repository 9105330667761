import React from 'react';

type Props = { color?: string };

const CloseSessionSVG = ({ color = 'white' }: Props) => {
	return (
		<svg
			version='1.1'
			id='Capa_1'
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			x='0px'
			y='0px'
			viewBox='0 0 16.7 17'
			xmlSpace='preserve'
		>
			<g>
				<g>
					<path
						d='M6,17H2c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2h4c0.6,0,1,0.4,1,1S6.6,2,6,2H2v13h4c0.6,0,1,0.4,1,1S6.6,17,6,17z'
						fill={color}
					/>
				</g>
				<g>
					<path
						d='M14.6,9.2c-0.3,0-0.5-0.1-0.7-0.3l-3.2-3.3c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l3.2,3.3c0.4,0.4,0.4,1,0,1.4
			C15.1,9.1,14.9,9.2,14.6,9.2z'
						fill={color}
					/>
				</g>
				<g>
					<path
						d='M11.4,12.4c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l3.2-3.2c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-3.2,3.2
			C11.9,12.3,11.6,12.4,11.4,12.4z'
						fill={color}
					/>
				</g>
				<g>
					<path
						d='M14.4,9.2H6c-0.6,0-1-0.4-1-1s0.4-1,1-1h8.5c0.6,0,1,0.4,1,1S15,9.2,14.4,9.2z'
						fill={color}
					/>
				</g>
			</g>
		</svg>
	);
};

export default CloseSessionSVG;
