'use client';
import { createContext, useContext, useEffect, useState } from 'react';

interface ContextGeneralType {
	showLogin: boolean;
	openMobileFilter: boolean;
	loadCurrency: boolean;
	currency: string;
	exchangeRate: Array<{
		label: string;
		value: string;
		change: string | number;
	}>;
	setShowLogin: (value: boolean) => void;
	saveCurrencyStorage: (value: string) => void;
	setExchangeRate: (
		value: Array<{
			label: string;
			value: string;
			change: string | number;
		}>
	) => void;
	handlerOpenMobileFilter: () => void;
	calculatePriceFromCurrency: (value: number) => number;
	getCurrencyStorage: () => void;
}
const ContextGeneral = createContext<ContextGeneralType>({
	showLogin: false,
	openMobileFilter: false,
	loadCurrency: false,
	currency: '',
	exchangeRate: [],
	setExchangeRate: () => {},
	setShowLogin: () => {},
	handlerOpenMobileFilter: () => {},
	calculatePriceFromCurrency: () => 0,
	saveCurrencyStorage: () => {},
	getCurrencyStorage: () => {},
});

export const GeneralProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [showLogin, setShowLogin] = useState<boolean>(false);
	const [openMobileFilter, setOpenMobileFilter] = useState<boolean>(false);
	const [currency, setCurrency] = useState<string>('');
	const [loadCurrency, setLoadCurrency] = useState<boolean>(false);
	const [exchangeRate, setExchangeRate] = useState<
		Array<{
			label: string;
			value: string;
			change: string | number;
		}>
	>([]);

	function getCurrencyStorage() {
		if (
			typeof window !== 'undefined' &&
			typeof window.localStorage !== 'undefined'
		) {
			const currencyLocal = localStorage.getItem('currency');

			if (currencyLocal) {
				setCurrency(currencyLocal);
			} else {
				localStorage.setItem('currency', 'USD');
			}
		}
		setLoadCurrency(true);
	}

	function saveCurrencyStorage(currency: string) {
		if (
			typeof window !== 'undefined' &&
			typeof window.localStorage !== 'undefined'
		) {
			localStorage.setItem('currency', currency);
			setCurrency(currency);
		}
	}

	function calculatePriceFromCurrency(price: number) {
		if (
			typeof window !== 'undefined' &&
			typeof window.localStorage !== 'undefined'
		) {
			const currencyStorage = localStorage.getItem('currency');

			if (currencyStorage) {
				if (exchangeRate.length > 0) {
					const exchangeRateFilter = exchangeRate.find(
						(e) => e.value === currencyStorage
					);
					if (exchangeRateFilter) {
						const change = exchangeRateFilter.change;
						return Number(price) * Number(change);
					}
					return price;
				}
			}
			return price;
		}
		return price;
	}

	function handlerOpenMobileFilter() {
		setOpenMobileFilter(!openMobileFilter);
	}

	useEffect(() => {
		getCurrencyStorage();
	}, []);

	return (
		<ContextGeneral.Provider
			value={{
				openMobileFilter,
				showLogin,
				currency,
				loadCurrency,
				exchangeRate,
				setExchangeRate,
				saveCurrencyStorage,
				getCurrencyStorage,
				calculatePriceFromCurrency,
				setShowLogin,
				handlerOpenMobileFilter,
			}}
		>
			{children}
		</ContextGeneral.Provider>
	);
};

export const useContextGeneral = () => useContext(ContextGeneral);

export default ContextGeneral;
