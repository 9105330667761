import { Box, Card, CardContent, Typography } from '@mui/material';
import { ICategoriesShow } from '@negotium/models';
import React from 'react';
import { ImageWithFallback } from '../ImageWithFallback';
import Link from 'next/link';

type CardCategoryProps = {
	lng: 'es' | 'en';
	category: ICategoriesShow;
};

const CardCategory = ({ lng, category }: CardCategoryProps) => {
	return (
		<Link
			href={`${lng}/catalog?categoriesId=${category.categoriesId}`}
			style={{
				textDecoration: 'none',
			}}
		>
			<Card
				sx={{
					background: 'white',
					borderRadius: 5,

					width: '90%',
					minHeight: { md: '320px', sm: '280px' },
					m: 'auto',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.4)',
				}}
			>
				{/* <Link
				href={`${lng}/catalog?categoriesId=${category.categoriesId}`}
				style={{
					textDecoration: 'none',
				}}
			> */}
				<Box width={{ xs: '100%', md: '100%' }}>
					<ImageWithFallback
						src={category.image.url}
						fallbackImage='/static/favicon/256x256.png'
						alt={category.name[lng]}
						width={category.image.width}
						height={category.image.height}
						style={{ width: '100%', height: 'auto' }}
					/>
				</Box>

				<CardContent
					sx={{
						minHeight: '80px',
					}}
				>
					<Typography
						gutterBottom
						variant='ExplicationTextBold'
						fontSize='clamp(0.7rem, 3vw,1rem)'
						lineHeight='1rem'
						textAlign='center'
					>
						{category.name[lng]}
					</Typography>
				</CardContent>
			</Card>
		</Link>
	);
};

export default CardCategory;
