import { ITropiPayCreateLink } from '@negotium/models';
import commonRequestHeaders from '../common/requestHeaders';
import { AxiosError } from 'axios';

export const createPaymentTropiPay = async ({
	lang,
	cartShopId,
	currency,
}: {
	cartShopId: string;
	currency: string;
	lang: 'es' | 'en';
}): Promise<ITropiPayCreateLink> => {
	try {
		const headers = await commonRequestHeaders();

		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/tropiPay`,
			{
				method: 'POST',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify({ lang, cartShopId, currency }),
			}
		);

		if (!promise.ok) {
			if (promise.status === 401) {
				throw new Error(promise.statusText);
			}
		}
		const { data, result, message } = await promise.json();
		if (result === 2) {
			throw new Error(message);
		}

		return {
			status: 200,
			data: data,
			message: message,
		};
	} catch (error) {
		throw new Error((error as AxiosError).message);
	}
};
